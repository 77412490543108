import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"

const Players = () => (
    <Layout>
      <SEO title="Player Roster" />
   
      <iframe src="https://sportsrecruits.com/organization/BobcatsShowcaseBaseball/embed/?type=players&teamid=8240&color=30A9DE" frameBorder="0" width="100%" height="800"></iframe>    

    </Layout>
  )
  
  export default Players